import { useAxios } from "@/plugins/axios";
import { Invoice, InvoicesListResponse } from "@/types/api";
import { defineStore } from "pinia";
import { ref, Ref } from "vue";

export const useInvoicesStore = defineStore("invoices", () => {
    const invoicesSelectedMobile: Ref<number[]> = ref([]);
    const invoicesSelectedDesktop: Ref<Invoice[]> = ref([]);
    const invoiceItems: Ref<Invoice[]> = ref([]);
    const invoiceItemsBusy = ref(false);

    const clearSelectionMobile = (): void => {
        invoicesSelectedMobile.value = [];
    };

    const selectAllMobile = (): void => {
        const newSelected: number[] = [];
        invoiceItems.value.forEach((item, index) => {
            newSelected.push(index);
        });

        invoicesSelectedMobile.value = newSelected;
    };

    const allSelectedMobile = (): boolean => {
        // If the lengths don't match, for sure we don't have everything selected
        if (invoiceItems.value.length !== invoicesSelectedMobile.value.length) return false;

        // If we have no invoiceItems to select, we claim we don't have everything selected
        if (invoiceItems.value.length === 0) return false;

        // Loop over all the invoiceItems to see if they are in the selectedItems array
        invoiceItems.value.forEach((item, index) => {
            if (invoicesSelectedMobile.value.indexOf(index) === -1) return false;
        });

        return true;
    };

    const refreshInvoices = async (customerId: number): Promise<void> => {
        const axios = useAxios();

        try {
            // clear everything first
            invoicesSelectedMobile.value = [];
            invoicesSelectedDesktop.value = [];
            invoiceItems.value = [];

            invoiceItemsBusy.value = true;

            const response = await axios.get(`/invoices/list/${customerId}`);
            const responseData: InvoicesListResponse = response.data;

            invoiceItems.value = responseData.invoices;
        } finally {
            invoiceItemsBusy.value = false;
        }
    };

    return {
        invoicesSelectedMobile,
        invoicesSelectedDesktop,
        invoiceItems,
        invoiceItemsBusy,
        clearSelectionMobile,
        selectAllMobile,
        allSelectedMobile,
        refreshInvoices,
    };
});
