import axios, { AxiosInstance } from "axios";
import router, { loginRoutes } from "@/router";
import { useMainStore } from "@/stores/main";
import { Pinia } from "pinia";

// configure the global instance or add handlers.
// it's then accessible throughout the app

// default options for every request.
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    withCredentials: true,
    timeout: 8000,
});

export function setupInterceptors(axiosInstance: AxiosInstance, pinia: Pinia) {
    // A hook that looks for 401/403 errors in every ajax call we make.
    // If we see one, we clear the current user from our memory, and
    // redirect to the login page (unless we already were staring at the login page).
    axiosInstance.interceptors.response.use(
        (response: any) => response,
        (error: any) => {
            const mainStore = useMainStore(pinia);

            if (error.response) {
                if (error.response?.status === 401 || error.response?.status === 403) {
                    mainStore.clearUser();

                    if (router.currentRoute?.name) {
                        if (!loginRoutes.includes(router.currentRoute.name)) {
                            router.push({ name: "login" });

                            return Promise.reject(error);
                        }
                    }
                }
            }

            return Promise.reject(error);
        }
    );
}

export function useAxios() {
    return axiosInstance;
}

export default axiosInstance;
