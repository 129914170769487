import Vue from "vue";
import App from "./App.vue";
import router, { setupRouteGuards } from "./router";
import vuetify from "./plugins/vuetify";
import { createPinia, PiniaVuePlugin } from "pinia";
import { useMainStore } from "./stores/main";
import { useConfigStore } from "./stores/config";
import { setupInterceptors, useAxios } from "./plugins/axios";

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

const axios = useAxios();
setupInterceptors(axios, pinia);

setupRouteGuards(router, pinia);

const mainStore = useMainStore(pinia);
const configStore = useConfigStore(pinia);

// Before 'Vue starts' (and removes our #app element), take the theme value of it and store it in the mainStore.
const appElement = document.getElementById("app");
mainStore.frontendTheme = appElement?.dataset.theme || "default";

configStore.startup().finally(() => {
    mainStore.refreshUser().finally(() => {
        new Vue({
            router,
            vuetify,
            pinia,
            render: (h) => h(App),
        }).$mount("#app");
    });
});
