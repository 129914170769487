import { useAxios } from "@/plugins/axios";
import { ConfigResponse, ContentItem, ContentItemMap } from "@/types/api";
import { defineStore } from "pinia";
import { ref, Ref } from "vue";

export const useConfigStore = defineStore("config", () => {
    const axios = useAxios();

    const config: Ref<ConfigResponse> = ref({
        titleChangeEnabled: false,
        loginRequiresSubscriptionId: false,
        deployLogo: null,
        content: {},
    });

    const storeConfig = (data: ConfigResponse): void => {
        localStorage.setItem("configStored", "true");
        localStorage.setItem("config", JSON.stringify(data));

        config.value = data;
    };

    const readLocalStorage = (): void => {
        const configJson = localStorage.getItem("config");
        if (configJson === null) return;

        const configData: ConfigResponse = JSON.parse(configJson);
        config.value = configData;
    };

    const fetchConfig = async (): Promise<void> => {
        try {
            const response = await axios.get("/config");
            const responseData: ConfigResponse = response.data;

            storeConfig(responseData);
        } finally {
        }
    };

    const startup = async (): Promise<void> => {
        if (localStorage.getItem("configStored") === "true") {
            // Read the config from localStorage, set it, and then start fetching updated config, but do not wait for completion.
            readLocalStorage();
            fetchConfig();

            return;
        }

        // If we have no local storage yet, start fetching config from the API but wait for this call to complete.
        await fetchConfig();
    };

    const contentItem = (category: string, key: string): string | undefined => {
        const items: ContentItemMap = config.value.content[category] || {};
        const item: ContentItem = items[key] || {};

        return item.content || undefined;
    };

    return {
        config,
        fetchConfig,
        startup,
        contentItem,
    };
});
