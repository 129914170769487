var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.relationsStore.fetchingRelations)?_c('v-row',[_c('v-col',[_c('v-progress-linear',{staticClass:"mx-auto mb-4",attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e(),(_setup.configStore.contentItem('subscriptions', 'nieuw abonnement link'))?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('a',{staticClass:"text-h6 d-block",attrs:{"href":_setup.configStore.contentItem('subscriptions', 'nieuw abonnement link'),"target":"_blank"}},[_vm._v(_vm._s(_setup.configStore.contentItem("subscriptions", "nieuw abonnement tekst")))])])],1):_vm._e(),_vm._l((_setup.subscriptions),function(subscription){return _c('v-row',{key:subscription.subscriptionId,staticClass:"my-4"},[_c('v-col',[_c('v-card',[_c('v-row',[_c('v-col',{staticClass:"px-5",attrs:{"cols":"12","md":"6"}},[(subscription.logoImage)?_c('v-img',{attrs:{"src":subscription.logoImage,"contain":"","height":"160","alt":`Afbeelding van ${subscription.configName}`}}):_vm._e(),_c('div',{staticClass:"px-3 mb-0"},[_c('div',[_vm._v(_vm._s(subscription.configName))])]),_c('v-card-subtitle',{staticClass:"py-0 mt-4"},[_c('p',{staticClass:"mb-1"},[_vm._v("Wordt bezorgd op:")]),_c('p',[_vm._v(" "+_vm._s(subscription.deliveryAddress?.street)+" "+_vm._s(subscription.deliveryAddress?.houseNo)+_vm._s(subscription.deliveryAddress?.houseNoExtra)+" te "+_vm._s(subscription.deliveryAddress?.city)+" ")])])],1),_c('v-col',{staticClass:"px-7 align-self-center",attrs:{"cols":"12","md":"6"}},[(subscription.complaintPossible)?_c('v-btn',{staticClass:"mb-2",attrs:{"text":"","tile":"","outlined":"","block":"","color":"primary","to":{
                                name: 'complaint_confirm',
                                params: { subscription: subscription.subscriptionId },
                            },"disabled":subscription.complaintLimited}},[(!subscription.complaintLimited)?_c('span',[_vm._v(_vm._s(_setup.configStore.contentItem("subscriptions", "bezorgklacht tekst")))]):_c('span',[_vm._v(" "+_vm._s(_setup.configStore.contentItem("subscriptions", "bezorgklacht mogelijk na"))+" ")])]):_vm._e(),_c('v-btn',{staticClass:"mb-2",attrs:{"text":"","tile":"","outlined":"","block":"","color":"primary","to":{
                                name: 'address_change_address',
                                params: { subscription: subscription.subscriptionId },
                            }}},[_vm._v(" "+_vm._s(_setup.configStore.contentItem("subscriptions", "adres wijzigen tekst"))+" ")]),(_setup.configStore.config.titleChangeEnabled)?_c('v-btn',{staticClass:"mb-2",attrs:{"text":"","tile":"","outlined":"","block":"","color":"primary","to":{
                                name: 'titlechange_list',
                                params: { subscription: subscription.subscriptionId },
                            }}},[_vm._v(" "+_vm._s(_setup.configStore.contentItem("subscriptions", "titel omzetten tekst"))+" ")]):_vm._e()],1)],1)],1)],1)],1)}),(_setup.noSubscriptions)?_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',[_vm._v("Geen abonnementen")])],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }