import { useAxios } from "@/plugins/axios";
import { RelationResponseMap, RelationResponse } from "@/types/api";
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { useMainStore } from "./main";

export const useRelationsStore = defineStore("relations", () => {
    const fetchingRelations: Ref<boolean> = ref(false);
    const relations: Ref<RelationResponseMap> = ref({});
    const mainStore = useMainStore();

    const currentRelation = (): RelationResponse | undefined => {
        const currentCustomerId = mainStore.getCurrentCustomerId();
        if (!currentCustomerId) {
            return undefined;
        }

        const key = String(currentCustomerId);
        const relation = relations.value[key] || undefined;

        return relation;
    };

    const fetchRelations = async (): Promise<void> => {
        const axios = useAxios();
        fetchingRelations.value = true;

        try {
            const response = await axios.get("/relations");
            if (response?.status === 200) {
                relations.value = response.data?.relations;
            }
        } finally {
            fetchingRelations.value = false;
        }
    };

    return {
        fetchingRelations,
        relations,
        fetchRelations,
        currentRelation,
    };
});
