import { useRelationsStore } from "@/stores/relations";
import { RelationSubscription, RelationSubscriptionMap } from "@/types/api";
import { computed, Ref } from "vue";

export function subscriptionFromProps(
    props: Readonly<{
        subscriptionId: number;
    }>,
    relationsStore: any
) {
    return computed((): RelationSubscription | undefined => {
        const currentRelation = relationsStore.currentRelation();

        const subscription = currentRelation?.subscriptions[props.subscriptionId] ?? undefined;
        if (subscription === undefined) {
            console.log(`Subscription ${props.subscriptionId} not found.`);

            return undefined;
        }

        return subscription;
    });
}

export function subscriptionForCurrentRelation(relationsStore: any) {
    return computed((): RelationSubscriptionMap | undefined => {
        const currentRelation = relationsStore.currentRelation();
        if (!currentRelation) {
            return undefined;
        }

        return currentRelation.subscriptions;
    });
}

export function determineSubscriptions(subscriptions: Ref<RelationSubscriptionMap | undefined>, relationsStore: any) {
    return computed((): boolean => {
        // While we're fetching relations, we don't know yet if we're empty or not
        if (relationsStore.fetchingRelations) {
            return false;
        }

        if (subscriptions.value === undefined) {
            return true;
        }

        return Object.keys(subscriptions.value).length === 0;
    });
}
