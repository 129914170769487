import { useAxios } from "@/plugins/axios";
import { RelationSelectRequest } from "@/types/api";
import { defineStore } from "pinia";
import { reactive, Ref, ref } from "vue";

interface CurrentUser {
    email: string | null;
    name: string | null;
    currentRelation: EmailRelation | null;
    relations: Array<EmailRelation>;
    roles: string[];
}

interface EmailRelation {
    customerId: number;
    name: string;
}

export const useMainStore = defineStore("main", () => {
    const navBarOpen: Ref<boolean | null> = ref(null);
    const frontendTheme = ref("default");
    const currentUser: CurrentUser = reactive({
        email: null,
        name: null,
        currentRelation: null,
        relations: [],
        roles: [],
    });

    const hasUser = (): boolean => !!currentUser.email && currentUser.relations.length > 0;

    const getCurrentCustomerId = (): number | undefined => currentUser?.currentRelation?.customerId;

    const clearUser = (): void => {
        currentUser.email = null;
        currentUser.name = null;
        currentUser.currentRelation = null;
        currentUser.relations = [];
        currentUser.roles = [];
    };

    const setUser = (sourceObject: any): void => {
        currentUser.email = sourceObject?.email;
        currentUser.name = sourceObject?.name;
        currentUser.currentRelation = sourceObject?.currentRelation;
        currentUser.relations = sourceObject?.relations;
        currentUser.roles = sourceObject?.roles;
    };

    const refreshUser = async (): Promise<void> => {
        const axios = useAxios();

        try {
            const response = await axios.get("/user");
            if (response?.status === 200) {
                setUser(response.data);

                return;
            }

            throw new Error("Invalid status code received");
        } catch (_err: any) {
            if (_err?.response?.status !== 401 && _err?.response?.status !== 403) {
                throw _err;
            }
        }
    };

    const selectRelation = async (customerId: number): Promise<void> => {
        const axios = useAxios();

        try {
            const request: RelationSelectRequest = {
                customerId,
            };

            const response = await axios.post("/relation_select", request);

            if (response?.status === 200) {
                setUser(response.data);

                return;
            }

            throw new Error("Invalid status code received");
        } catch (_err: any) {
            if (_err?.response?.status !== 401 && _err?.response?.status !== 403) {
                throw _err;
            }
        }
    };

    const logoutUser = async (): Promise<void> => {
        const axios = useAxios();

        try {
            await axios.get("/logout");
        } catch (_err: any) {
            if (_err?.response?.status !== 401 && _err?.response?.status !== 403) {
                throw _err;
            }

            throw new Error("User session became invalid");
        } finally {
            await refreshUser();
        }
    };

    return {
        navBarOpen,
        currentUser,
        hasUser,
        clearUser,
        refreshUser,
        selectRelation,
        logoutUser,
        getCurrentCustomerId,
        frontendTheme,
    };
});
