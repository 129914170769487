import Vue, { nextTick } from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import AppBase from "../views/AppBase.vue";

import SubscriptionView from "../views/SubscriptionView.vue";
import RelationSelectView from "../views/RelationSelectView.vue";
import Error404View from "../views/Error/Error404View.vue";

const LoginBase = () => import(/* webpackChunkName: "login" */ "../views/Login/LoginBase.vue");
const LoginView = () => import(/* webpackChunkName: "login" */ "../views/Login/LoginView.vue");
const LoginNoUserView = () => import(/* webpackChunkName: "login" */ "../views/Login/NoUserView.vue");
const LoginTokenSentView = () => import(/* webpackChunkName: "login" */ "../views/Login/TokenSentView.vue");
const LoginFailureView = () => import(/* webpackChunkName: "login" */ "../views/Login/FailureView.vue");

const InvoicesList = () => import(/* webpackChunkName: "invoices" */ "../views/Invoices/List.vue");
const InvoicesEmail = () => import(/* webpackChunkName: "invoices" */ "../views/Invoices/Email.vue");
const InvoicesDone = () => import(/* webpackChunkName: "invoices" */ "../views/Invoices/Done.vue");

const ComplaintConfirm = () => import(/* webpackChunkName: "complaint" */ "../views/Complaint/Confirm.vue");
const ComplaintDone = () => import(/* webpackChunkName: "complaint" */ "../views/Complaint/Done.vue");
const ComplaintChangeAddress = () => import(/* webpackChunkName: "complaint" */ "../views/Complaint/ChangeAddress.vue");

const AddressDone = () => import(/* webpackChunkName: "complaint" */ "../views/Address/Done.vue");
const AddressChangeAddress = () => import(/* webpackChunkName: "address" */ "../views/Address/ChangeAddress.vue");

const TitleChangeList = () => import(/* webpackChunkName: "titlechange" */ "../views/TitleChange/List.vue");

import { useMainStore } from "@/stores/main";
import { Pinia } from "pinia";
import { useConfigStore } from "@/stores/config";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        redirect: "subscriptions",
    },
    {
        path: "/login",
        component: LoginBase,
        children: [
            {
                path: "",
                name: "login",
                component: LoginView,
                meta: {
                    title: "Inloggen via e-mail",
                },
            },
            {
                path: "/login/no_user",
                name: "login_no_user",
                component: LoginNoUserView,
                meta: {
                    title: "Inloggen via e-mail",
                },
            },
            {
                path: "/login/token_sent",
                name: "login_token_sent",
                component: LoginTokenSentView,
                meta: {
                    title: "Inloggen via e-mail",
                },
            },
            {
                path: "/login-failure",
                name: "login_failure",
                component: LoginFailureView,
                meta: {
                    title: "Link niet geldig",
                },
            },
        ],
    },
    {
        path: "/",
        component: AppBase,
        children: [
            {
                path: "/relation",
                name: "relation_select",
                component: RelationSelectView,
                meta: {
                    title: "Kies de juiste klant",
                },
            },
            {
                path: "/subscriptions",
                name: "subscriptions",
                component: SubscriptionView,
                meta: {
                    title: "Abonnementen",
                },
            },
            {
                path: "/invoices",
                name: "invoices_list",
                component: InvoicesList,
                meta: {
                    title: "Facturen",
                },
            },
            {
                path: "/invoices/email",
                name: "invoices_email",
                component: InvoicesEmail,
                meta: {
                    title: "Facturen",
                    backButton: "invoices_list",
                },
            },
            {
                path: "/invoices/done",
                name: "invoices_done",
                component: InvoicesDone,
                meta: {
                    title: "Facturen",
                    backButton: "invoices_list",
                },
            },
            {
                path: "/complaint/confirm/:subscription",
                name: "complaint_confirm",
                component: ComplaintConfirm,
                meta: {
                    title: "Bezorgklacht indienen",
                    backButton: "subscriptions",
                },
                props: (route) => {
                    const subscriptionIdParsed = Number.parseInt(route.params.subscription);
                    const subscriptionId = Number.isNaN(subscriptionIdParsed) ? 0 : subscriptionIdParsed;

                    return {
                        subscriptionId,
                    };
                },
            },
            {
                path: "/complaint/done/:addressChanged/:subscription",
                name: "complaint_done",
                component: ComplaintDone,
                meta: {
                    title: "Bezorgklacht indienen",
                    backButton: "subscriptions",
                },
                props: (route) => {
                    const subscriptionIdParsed = Number.parseInt(route.params.subscription);
                    const subscriptionId = Number.isNaN(subscriptionIdParsed) ? 0 : subscriptionIdParsed;
                    const addressChanged = route.params.addressChanged == "1";

                    return {
                        subscriptionId,
                        addressChanged,
                    };
                },
            },
            {
                path: "/complaint/address/:subscription",
                name: "complaint_change_address",
                component: ComplaintChangeAddress,
                meta: {
                    title: "Bezorgklacht indienen",
                    backButton: "subscriptions",
                },
                props: (route) => {
                    const subscriptionIdParsed = Number.parseInt(route.params.subscription);
                    const subscriptionId = Number.isNaN(subscriptionIdParsed) ? 0 : subscriptionIdParsed;

                    return {
                        subscriptionId,
                    };
                },
            },
            {
                path: "/address/change/:subscription",
                name: "address_change_address",
                component: AddressChangeAddress,
                meta: {
                    title: "Adres wijzigen",
                    backButton: "subscriptions",
                },
                props: (route) => {
                    const subscriptionIdParsed = Number.parseInt(route.params.subscription);
                    const subscriptionId = Number.isNaN(subscriptionIdParsed) ? 0 : subscriptionIdParsed;

                    return {
                        subscriptionId,
                    };
                },
            },
            {
                path: "/address/done/:subscription",
                name: "address_done",
                component: AddressDone,
                meta: {
                    title: "Adres wijzigen",
                    backButton: "subscriptions",
                },
                props: (route) => {
                    const subscriptionIdParsed = Number.parseInt(route.params.subscription);
                    const subscriptionId = Number.isNaN(subscriptionIdParsed) ? 0 : subscriptionIdParsed;

                    return {
                        subscriptionId,
                    };
                },
            },
            {
                path: "/titlechange/list/:subscription",
                name: "titlechange_list",
                component: TitleChangeList,
                meta: {
                    title: "Titel omzetten naar andere titel",
                    backButton: "subscriptions",
                },
                props: (route) => {
                    const subscriptionIdParsed = Number.parseInt(route.params.subscription);
                    const subscriptionId = Number.isNaN(subscriptionIdParsed) ? 0 : subscriptionIdParsed;

                    return {
                        subscriptionId,
                    };
                },
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: Error404View,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export function setupRouteGuards(router: VueRouter, pinia: Pinia) {
    router.beforeEach((to, _from, next) => {
        const mainStore = useMainStore(pinia);
        const configStore = useConfigStore(pinia);

        // intercept the navigation. Redirect to the login view if we're not authenticated.
        if (to.name && !loginRoutes.includes(to.name)) {
            if (!mainStore.hasUser()) {
                next({ name: "login" });

                return;
            }
        }

        // Intercept navigation to check if we have a relation selected
        if (to.name !== "relation_select") {
            if (mainStore.hasUser()) {
                if (mainStore.currentUser.currentRelation === null) {
                    next({ name: "relation_select" });

                    return;
                }
            }
        }

        // Intercept navigation to the title_change routes if it's disabled
        if (to.name && titleChangeRoutes.includes(to.name)) {
            if (!configStore.config.titleChangeEnabled) {
                next({ name: "subscriptions" });

                return;
            }
        }

        next();
    });

    router.afterEach((to) => {
        const configStore = useConfigStore(pinia);

        if (to?.meta?.title) {
            nextTick(() => {
                document.title = `${configStore.contentItem("global", "customer title")}: ${to?.meta?.title}`;
            });
        }
    });
}

// Array of route names where we don't require a logged in user
export const loginRoutes = ["login", "login_no_user", "login_token_sent", "login_failure", "404"];
export const titleChangeRoutes = ["titlechange_list"];

export default router;
