import Vue from "vue";
import Vuetify from "vuetify/lib";
import { getCurrentInstance } from "vue";

import InstagramIcon from "../components/InstagramIcon.vue";
import FacebookIcon from "../components/FacebookIcon.vue";
import LinkedinIcon from "../components/LinkedinIcon.vue";
import TwitterIcon from "../components/TwitterIcon.vue";

import nl from "vuetify/src/locale/nl";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { nl },
        current: "nl",
    },
    theme: {
        themes: {
            light: {
                primary: "#000000",
                secondary: "#ffffff",
            },
        },
    },
    icons: {
        values: {
            instagram: {
                component: InstagramIcon,
            },
            facebook: {
                component: FacebookIcon,
            },
            linkedin: {
                component: LinkedinIcon,
            },
            twitter: {
                component: TwitterIcon,
            },
        },
    },
});

export function useVuetify() {
    const instance = getCurrentInstance();
    if (!instance) {
        throw new Error(`useVuetify should be called in setup().`);
    }
    return instance.proxy.$vuetify;
}
